import React from 'react';
import ImageComponent from './ImageComponent';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import ContentNav from './ContentNav';
import { scrollHeight } from '../StartPageContentRender';
import { theme } from '../../Theming/Theming';

const FullImageWrapper = styled.div`
  position: relative;
  .image-component-wrapper .image-wrapper [data-flight-image-container] {
    padding-bottom: 0 !important;
  }
  [data-flight-image-container] {
    img,
    video {
      position: static !important;
      ${scrollHeight}
      object-fit: cover;
      opacity: 1;
      transition: opacity 500ms ease 0s;
    }
    &[data-flight-image-loaded='true'] [data-flight-image-placeholder] {
      display: none;
    }
  }
  .content-html-text {
    padding: 10% 5% !important;
    ${theme.below.md} {
      padding: 5rem 5% !important;
    }
  }
`;

export const FullImage = ({ data, index, items }) => {
  return (
    <Above breakpoint="lg">
      {(matches) => {
        return (
          <FullImageWrapper className="full-image-wrapper">
            <ImageComponent
              data={data}
              columns={1}
              aspect={matches ? '1:1' : '1:1'}
              key={index}
              sizes={[1, 1, 1, 1]}
              crop={true}
              critical={true}
            />
            <ContentNav items={items} data={data} />
          </FullImageWrapper>
        );
      }}
    </Above>
  );
};

export default FullImage;
