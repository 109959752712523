import React from 'react';
import { ContentStyles } from './ContentText';
import FullImage from './Components/FullImage';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import Footer from '../Layout/Footer/Footer';

export const scrollHeight = `
  height: 100dvh !important;
  ${theme.above.md} {
    height: 100dvh !important;
  }
  ${theme.above.lg} {
    height: 100dvh !important;
  }
`;

const ScrollContainer = styled.div`
  ${scrollHeight}
  scroll-snap-type: y mandatory;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  overflow-y: scroll;
  .panel {
    scroll-snap-align: start;
    ${scrollHeight}
  }
  .footer {
    .footer-wrapper {
      padding-top: 140px;
      .footer-blocks {
        scroll-snap-align: start;
      }
      .logo {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
`;

const ElementHandler = ({ row, index, items }) => {
  const type = row.type.toUpperCase();
  index = index + 1;
  switch (type) {
    case 'FULL-IMAGE':
      return <FullImage data={row} index={index} items={items} />;
    default:
      return null;
  }
};

export const StartPageContentRender = ({ data, className = '' }) => {
  const items = data?.items;
  if (!items || items.length === 0) return null;
  return (
    <>
      <ContentStyles className={`content-render-wrapper ${className}`}>
        <ScrollContainer className="scroll-container">
          {items.map((row, index) => (
            <section className="panel" key={index}>
              <ElementHandler row={row} index={index} items={items} />
            </section>
          ))}
          <section className="panel footer">
            <Footer />
          </section>
        </ScrollContainer>
      </ContentStyles>
    </>
  );
};
