import React from 'react';
import { styled } from 'linaria/react';
import { convertPropsToObject } from '../ContentRender';
import { theme } from '../../Theming/Theming';

const Wrapper = styled.div`
  position: absolute;
  bottom: 50px;
  ${theme.below.sm} {
    bottom: 70px;
  }
  width: 100%;
  > ul {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    > li {
      color: white;
      text-align: center;
      position: relative;
      h4 {
        font-weight: ${theme.weights.light};
        ${theme.below.sm} {
          display: none;
        }
      }
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background: white;
        position: absolute;
        bottom: calc(100% - 43px);
        left: 0;
      }
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white;
        background: white;
        position: absolute;
        bottom: calc(100% - 50px);
        left: calc(50% - 8px);
      }
      &:first-child {
        &:before {
          width: 50%;
          left: 50%;
        }
      }
      &:last-child {
        &:before {
          width: 50%;
        }
      }
      &.current {
        h4 {
          font-weight: ${theme.weights.bold};
        }
        &:after {
          border-color: black;
        }
      }
    }
  }
`;

const ContentNav = ({ items, data }) => {
  const count = items.length;
  const currentProps = convertPropsToObject(data);
  const currentName = currentProps?.name?.value;
  return (
    <Wrapper>
      <ul className="inner" data-count={count}>
        {items.map((item, index) => {
          const props = convertPropsToObject(item);
          const name = props.name?.value;
          return (
            <li
              key={index}
              className={`${currentName === name ? 'current' : ''}`}
              style={{ width: 100 / count + '%' }}
            >
              <h4>{name}</h4>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default ContentNav;
