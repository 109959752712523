import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import startPageQuery from './StartPageQuery.gql';
import Head from '@jetshop/core/components/Head';
import StartPageLoadingState from './StartPageLoadingState';
import { StartPageContentRender } from '../ContentRender/StartPageContentRender';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId },
  });
  const { loading, data } = result;
  if (loading || !data) return <StartPageLoadingState />;

  if (data && data.startPage)
    return (
      <StartPageWrapper>
        {result.data.startPage && (
          <Head
            data={result.data.startPage}
            route={result.data.startPage.primaryRoute}
          />
        )}
        <StartPageContentRender data={data.startPage.data} />
      </StartPageWrapper>
    );
  else return null;
};

export default StartPage;
